import { ptBR as corePtBR } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { ptBR } from '@mui/x-data-grid';
import { Inter, Manrope, Sora, Syne } from 'next/font/google';
import {
  errorColor,
  neutralColor,
  primaryColor,
  secondaryColor,
} from './theme/colors';

const sora = Sora({subsets: ['latin'], variable: '--default-font'});

const inter = Inter({subsets: ['latin']});
const manrope = Manrope({subsets: ['latin']});
const syne = Syne({subsets: ['greek']});

export default function getTheme() {
  return {
    theme: createTheme(
      {
        palette: {
          primary: primaryColor,
          neutral: neutralColor,
          secondary: secondaryColor,
          ochre: {
            main: '#E3D026',
            light: '#E9DB5D',
            dark: '#A29415',
            lighter: '#E9DB5D',
            darker: '#A29415',
            contrastText: '#fff',
          },
          background: {
            default: neutralColor[100],
          },
          error: errorColor,
        },
        typography: {
          fontFamily: [
            inter.style.fontFamily,
            sora.style.fontFamily,
            manrope.style.fontFamily,
            syne.style.fontFamily,
            'Arial',
            'sans-serif',
          ],
          'display-large': {
            fontFamily: sora.style.fontFamily,
            fontSize: '57px',
            fontWeight: 700,
            lineHeight: '64px',
          },
          'display-medium': {
            fontFamily: sora.style.fontFamily,
            fontSize: '45px',
            fontWeight: 700,
            lineHeight: '52px',
          },
          'display-small': {
            fontFamily: sora.style.fontFamily,
            fontSize: '36px',
            fontWeight: 700,
            lineHeight: '44px',
          },
          'headline-large': {
            fontFamily: sora.style.fontFamily,
            fontSize: '32px',
            fontWeight: 700,
            lineHeight: '40px',
          },
          'headline-medium': {
            fontFamily: manrope.style.fontFamily,
            fontSize: '28px',
            fontWeight: 700,
            lineHeight: '36px',
          },
          'headline-small': {
            fontFamily: manrope.style.fontFamily,
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '32px',
          },
          'title-large': {
            fontFamily: manrope.style.fontFamily,
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '28px',
          },
          'title-medium': {
            fontFamily: manrope.style.fontFamily,
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '24px',
          },
          'title-small': {
            fontFamily: manrope.style.fontFamily,
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '20px',
          },
          'label-large': {
            fontFamily: sora.style.fontFamily,
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
          },
          'label-medium': {
            fontFamily: inter.style.fontFamily,
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
          },
          'label-small': {
            fontFamily: inter.style.fontFamily,
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '16px',
          },
          'body-large': {
            fontFamily: inter.style.fontFamily,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
          },
          'body-medium': {
            fontFamily: inter.style.fontFamily,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
          },
          'body-small': {
            fontFamily: inter.style.fontFamily,
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
          },
        },
        components: {
          MuiTypography: {
            defaultProps: {
              variantMapping: {
                'display-large': 'h1',
                'display-medium': 'h1',
                'display-small': 'h2',
                'headline-large': 'h1',
                'headline-medium': 'h2',
                'headline-small': 'h3',
                'title-large': 'h3',
                'title-medium': 'h4',
                'title-small': 'h5',
                'label-large': 'label',
                'label-medium': 'label',
                'label-small': 'label',
                'body-large': 'p',
                'body-medium': 'p',
                'body-small': 'p',
              },
              variant: 'body-medium',
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                fontFamily: inter.style.fontFamily,
                textTransform: 'none',
                fontWeight: 600,
                // fontSize: '1rem',
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none',
                },
                '&:active': {
                  boxShadow: 'none',
                },
              },
              purple: {
                backgroundColor: '#D0CFFC',
                color: '#4744A3',
                '&:hover': {
                  backgroundColor: '#C0BFEF',
                },
              },
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                boxShadow: 'none',
                borderRadius: '12px !important',
                border: '1px solid',
                borderColor: neutralColor[400],
                backgroundColor: 'white',
              },
            },
          },
          MuiCardHeader: {
            styleOverrides: {
              root: {},
              title: {
                fontSize: '20px',
                fontWeight: 700,
              },
            },
          },
          MuiAccordion: {
            styleOverrides: {
              root: {
                boxShadow: 'none',
                borderRadius: '12px !important',
                border: '1px solid',
                borderColor: neutralColor[400],
                '&:before': {
                  display: 'none',
                },
              },
            },
          },
          MuiAccordionDetails: {
            styleOverrides: {
              root: {
                borderTop: '1px solid #D8DAE5',
              },
            },
          },
          MuiFormControl: {
            styleOverrides: {
              root: {
                display: 'flex',
                margin: '8px 0',
                backgroundColor: 'transparent !important',
              },
            },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                backgroundColor: '#FFF',
              },
            },
          },
          MuiTextField: {
            styleOverrides: {
              root: {
                fontFamily: inter.style.fontFamily,
                backgroundColor:
                  'var(--color-tokens-button-button-secondary-btn-secondary-background)',
              },
            },
            defaultProps: {
              fullWidth: true,
            },
          },
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                marginBottom: '4px',
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              root: {
                width: 56,
                height: 32,
                padding: 0,
              },
              track: {
                borderRadius: 100,
              },
              switchBase: {
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 6,
                paddingRight: 6,
                '&.Mui-checked': {
                  '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                      primaryColor.contrastText
                    )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                  },
                },
              },
              thumb: {
                boxShadow: 'none',
                width: 24,
                height: 24,
                margin: 0,
                '&:before': {
                  content: "''",
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  left: 0,
                  top: 0,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="17" width="16" viewBox="0 0 17 16"><path fill="${encodeURIComponent(
                    primaryColor.constastText
                  )}" d="M13.1673 4.2735L12.2273 3.3335L8.50065 7.06016L4.77398 3.3335L3.83398 4.2735L7.56065 8.00016L3.83398 11.7268L4.77398 12.6668L8.50065 8.94016L12.2273 12.6668L13.1673 11.7268L9.44065 8.00016L13.1673 4.2735Z"/></svg>')`,
                },
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: primaryColor.dark,
                color:
                  'var(--color-tokens-button-button-secondary-btn-secondary-background)',
              },
            },
          },
          MuiPopover: {
            defaultProps: {
              container: () => document.getElementById('theme-root'),
            },
          },
          MuiModal: {
            defaultProps: {
              container: () => document.getElementById('theme-root'),
            },
          },
          MuiTooltip: {
            defaultProps: {
              PopperProps: {
                container: () => document.getElementById('theme-root'),
              },
            },
          },
          MuiBackdrop: {
            styleOverrides: {
              root: {
                backgroundColor: 'rgba(0, 0, 0, 0.92)',
              },
            },
          },
          MuiDialogTitle: {
            defaultProps: {
              PaperProps: {
                sx: {
                  backgroundColor: 'var(--neutral-0)',
                },
              },
            },
            styleOverrides: {
              root: {
                fontWeight: 700,
              },
            },
          },
          MuiDataGrid: {
            styleOverrides: {
              root: {
                borderRadius: 0,
                border: 0,
                fontFamily: inter.style.fontFamily,
              },
              columnHeaders: {
                backgroundColor: neutralColor[100],
                borderRadius: 0,
              },
              footerContainer: {
                border: 0,
              },
            },
          },
        },
      },
      ptBR,
      corePtBR
    ),
    fontClass: manrope,
  };
}
