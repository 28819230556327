export const errorMessage = {
  "not_allowed" : {
      title: "Acesso Negado",
      body: "Desculpe, você não tem permissão para acessar este conteúdo. Se você acredita que isso é um erro, entre em contato com o administrador"
  },
  "unbound" : {
      title: "Sem Vínculo",
      body: "Olá! Parece que você ainda não foi vinculado a uma empresa. Peça ajuda ao administrador da sua empresa!"
  },
  "internal_error" : {
      title: "Erro Interno",
      body: "Encontramos um problema interno em nossos servidores. Nossa equipe técnica foi notificada e está trabalhando para resolver o problema."
  },
  "bad_request" : {
      title: "Parece que houve um problema com sua solicitação.",
      body: "Verifique se todas as informações foram preenchidas corretamente... Se o problema persistir, entre em contato com nosso suporte técnico."
  },
  "unexpected" : {
      title: "Ocorreu um erro inesperado.",
      body: "Tente recarregar a página ou voltar mais tarde. Se o problema continuar, por favor, informe nosso suporte técnico."
  },
}