import {ThemeProvider} from '@mui/material/styles';
import {SessionProvider} from 'next-auth/react';
import React from 'react';

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

import {
  Hydrate,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';

import {AuthPageGate} from '@/config/auth';
import getTheme from '@/config/theme';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import {SnackbarProvider, closeSnackbar} from 'notistack';

import {buildBatchedGetRequest} from '@/hooks/base_hooks';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

import 'dayjs/locale/pt-br';

import * as Sentry from '@sentry/nextjs';
import posthog from 'posthog-js';
import {PostHogProvider} from 'posthog-js/react';
import ErrorDisplay from '@/components/Error/ErrorDisplay';

dayjs.extend(isoWeek);

if (typeof window !== 'undefined') {
  const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
  const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST;

  posthog.init(posthogKey, {
    api_host: posthogHost || 'https://app.posthog.com',
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') {
        posthog.debug();
      }
    },
  });
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false, error: null};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true, error: error};
  }

  getErrorType(errorStatus) {
    if (errorStatus >= 500) {
      return 'internal_error';
    } 
    if (errorStatus >= 400 && errorStatus < 500) {
      return 'bad_request';
    }
    return 'unexpected';
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, {extra: errorInfo});
  }

  render() {
    if (this.state.hasError) {
      const errorStatus = this.state.error?.status ?? 0;

      return <ErrorDisplay error={this.getErrorType(errorStatus)} />;
    }
    return this.props.children;
  }
}

export default function App({Component, pageProps: {session, ...pageProps}}) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({}),
        defaultOptions: {
          queries: {
            queryFn: ({queryKey}) => {
              return buildBatchedGetRequest(queryKey, {})();
            },
            staleTime: 1000 * 60 * 3, // 30 seconds
          },
        },
      })
  );

  const {theme} = getTheme();

  return (
  <ThemeProvider theme={theme}>
    <ErrorBoundary>
      <PostHogProvider client={posthog}>
        <SessionProvider session={session}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <ReactQueryDevtools />
                <div id="theme-root">
                  <CssBaseline />
                  <SnackbarProvider
                    autoHideDuration={3000}
                    preventDuplicate={true}
                    action={(snackbarId) => (
                      <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => closeSnackbar(snackbarId)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  />
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="pt-br"
                  >
                    <AuthPageGate
                      isPublic={Component?.auth?.isPublic}
                      permissions={Component?.auth?.permissions}
                    >
                      <Component {...pageProps} />
                    </AuthPageGate>
                  </LocalizationProvider>
                </div>
            </Hydrate>
          </QueryClientProvider>
        </SessionProvider>
      </PostHogProvider>
    </ErrorBoundary>
  </ThemeProvider>
  );
}
