import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material" 
import { GppMaybe } from "@mui/icons-material/"
import { errorMessage } from "./errorMessage"

function ErrorDisplay({error}) {
    return (
        <Box sx={{
            display: 'flex',
            height: '100vh',
            bgcolor: 'grey.100',
            justifyContent: 'center'
        }}>
            <Container sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Paper elevation={3} sx={{p: 4, textAlign: 'center', height: '400px', width:'75%'}}>
                    <Grid container flexDirection={'column'} alignItems='center'>
                        <GppMaybe color="error" sx={{fontSize: 60, mb: 2}}/>
                        <Typography variant="title-large">{errorMessage[error]?.title}</Typography>
                        <Grid item xs={7} my={4}>
                            <Typography variant="body-large" paragraph>{errorMessage[error]?.body}</Typography>
                        </Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => window.location.href = '/' }
                            >
                        Retornar
                        </Button>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    )
}

export default ErrorDisplay 